import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Address.css";

const AddressForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [productId, setProductId] = useState("");
  const [size, setSize] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setProductId(params.get("productId"));
    setSize(params.get("size"));
  }, [location]);

  const [showLandmarkField, setShowLandmarkField] = useState(false);
  const [showFreeShipping, setShowFreeShipping] = useState(true);
  const [formData, setFormData] = useState({
    pincode: "",
    city: "",
    state: "",
    fullName: "",
    phone: "",
    email: "",
    houseNo: "",
    area: "",
    landmark: "",
  });
  const [errors, setErrors] = useState({});

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  const toggleLandmarkField = () => setShowLandmarkField(!showLandmarkField);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.fullName) newErrors.fullName = "Full name is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.houseNo) newErrors.houseNo = "House number is required";
    if (!formData.area) newErrors.area = "Area is required";

    setErrors(newErrors);
    setShowFreeShipping(Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  };

  const handleProceedClick = () => {
    if (validateForm()) {
      handleRazorpayPayment();
    }
  };

  console.log("Form Data:", formData);
  // console.log([
  //   "pincode",
  //   "city",
  //   "state",
  //   "fullName",
  //   "phone",
  //   "email",
  //   "houseNo",
  //   "area",
  //   "landmark",
  // ]);
  const {
    pincode,
    city,
    state,
    fullName,
    phone,
    email,
    houseNo,
    area,
    landmark,
  } = formData;
  const handleRazorpayPayment = async () => {
    try {
      const response = await fetch("https://backend-ati-81lf.onrender.com/api/order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          productId,
          size,
          pincode,
          city,
          state,
          fullName,
          phone,
          email,
          houseNo,
          area,
          landmark,
        }),
      });

      var data = await response.json();
      console.log("Razorpay Response:", data);
      const { amount, currency } = data;

      const options = {
        key: "rzp_test_HkEb4IfeZCjusU",
        amount,
        currency,
        name: "Atnatic",
        description: "",
        image: "",
        order_id: data.orderId,
        handler: async function (response) {
          console.log("Razorpay Response:", response);
          const { razorpay_payment_id: paymentId } = response;

          // Call the payment-complete endpoint
          console.log("Payment ID:OrderId", paymentId, data.orderId);
          const paymentCompleteResponse = await fetch(
            "https://backend-ati-81lf.onrender.com/api/order/payment-complete",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                orderId: data.orderId,
                paymentId,
              }),
            }
          );
          const paymentCompleteData = await paymentCompleteResponse.json();
          const awbNo = paymentCompleteData.data.awbNo;
          console.log(
            "Payment Complete Response:",
            paymentCompleteData.data.awbNo
          );
          

          navigate(
            `/success?orderId=${data.orderId}&paymentId=${paymentId}&awbNo=${awbNo}`
          ); 
        },
        prefill: {
          name: formData.fullName,
          email: formData.email,
          contact: formData.phone,
        },
        notes: {
          address: `${formData.houseNo}, ${formData.area}, ${formData.city}, ${formData.state}, ${formData.pincode}`,
        },
        theme: { color: "#000000", background_color: "#ffffff" },
        modal: { backdropclose: false, escape: false },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error in payment:", error);
    }
  };

  // const generateBlueDartWaybill = async (formData) => {
  //   try {
  //     const tokenResponse = await axios.get(
  //       "https://backend-ati-81lf.onrender.com/api/bluedart/token"
  //     );
  //     if (tokenResponse.status === 200) {
  //       const jwtToken = tokenResponse.data.token;

  //       const pickupDate = `/Date(${Date.now()})/`;

  //       const requestData = {
  //         Request: {
  //           Consignee: {
  //             ConsigneeName: formData.fullName,
  //             ConsigneeMobile: formData.phone,
  //             ConsigneeEmailID: formData.email,
  //             ConsigneeAddress1: formData.houseNo,
  //             ConsigneeAddress2: formData.area,
  //             ConsigneeAddress3: formData.landmark || "",
  //             ConsigneePincode: formData.pincode,
  //             ConsigneeCityName: formData.city,
  //             ConsigneeStateCode: formData.state,
  //             ConsigneeCountryCode: "IN",
  //           },
  //           Services: {
  //             ActualWeight: 0.5,
  //             PieceCount: 1,
  //             ProductCode: "D",
  //             PickupDate: pickupDate,
  //             PickupTime: "1600",
  //             OTPBasedDelivery: "2",
  //             CreditReferenceNo: "YourOrderID",
  //           },
  //           Shipper: {
  //             CustomerName: "Atnaitc",
  //             CustomerMobile: "9988349333",
  //             CustomerAddress1:
  //               "1/7, Old Bustand, Gantashala Statue,TIRUCHANUR",
  //             CustomerPincode: "517503",
  //             OriginArea: "TPT",
  //           },
  //         },
  //         Profile: {
  //           Api_type: "S",
  //           LicenceKey: process.env.BLUEDART_LICENCE_KEY,
  //           LoginID: process.env.BLUEDART_LOGIN_ID,
  //         },
  //       };

  //       // Send request to BlueDart API
  //       const response = await axios.post(
  //         "https://apigateway-sandbox.bluedart.com/in/transportation/waybill/v1/GenerateWayBill",
  //         requestData,
  //         {
  //           headers: {
  //             "content-type": "application/json",
  //             JWTToken: jwtToken,
  //           },
  //         }
  //       );

  //       console.log("Waybill generated:", response.data);
  //     } else {
  //       throw new Error("Failed to fetch JWT token");
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 404) {
  //       console.error("API Endpoint not found (404):", error.response.data);
  //     } else {
  //       console.error("Error generating waybill:", error.message || error);
  //     }
  //   }
  // };

  // generateBlueDartWaybill(formData);

  return (
    <div className="main-content">
      <div className="address-form">
        <h2 className="h2-address">ADD DELIVERY ADDRESS</h2>
        <form>
          {/* Address Form Inputs */}
          <div className="form-group">
            <input
              type="text"
              name="fullName"
              placeholder="Full name"
              value={formData.fullName}
              onChange={handleChange}
            />
            {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              name="phone"
              placeholder="Phone number"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-row">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
            {errors.city && <span className="error">{errors.city}</span>}
            <select name="state" value={formData.state} onChange={handleChange}>
              <option value="" disabled>
                Select State
              </option>
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {errors.state && <span className="error">{errors.state}</span>}
          </div>
          <div className="form-group">
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={formData.pincode}
              onChange={handleChange}
            />
            {errors.pincode && <span className="error">{errors.pincode}</span>}
          </div>
          <div className="form-group">
            <input
              type="text"
              name="houseNo"
              placeholder="House no / Building / Apartment"
              value={formData.houseNo}
              onChange={handleChange}
            />
            {errors.houseNo && <span className="error">{errors.houseNo}</span>}
          </div>
          <div className="form-group">
            <input
              type="text"
              name="area"
              placeholder="Area, Sector, Street, Village"
              value={formData.area}
              onChange={handleChange}
            />
            {errors.area && <span className="error">{errors.area}</span>}
          </div>
          <div className="optional-group">
            <div className="add-landmark" onClick={toggleLandmarkField}>
              + Add landmark (optional)
            </div>
            {showLandmarkField && (
              <div className="form-group">
                <input
                  type="text"
                  name="landmark"
                  placeholder="Landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                />
              </div>
            )}
            {showFreeShipping && (
              <div className="free-shipping-label">
                <span className="icon"></span> Free Shipping{" "}
                <span className="free-badge">FREE</span>
              </div>
            )}
            <div className="btn btn-dark" onClick={handleProceedClick}>
              PROCEED
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressForm;
