import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkmark } from "react-checkmark";
import "./SuccessPage.css";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("orderId");
  const paymentId = params.get("paymentId");
  const awbNo = params.get("awbNo");

  const handleContinueShopping = () => {
    navigate("/");
  };

  return (
    <div className="success-page">
      <h1>Thank You for Shopping with ATNATIC!</h1>
      <p>
        Your order was successful, and a confirmation email is on its way to
        your inbox. We can’t wait for you to rock your new product.
      </p>
      {orderId && <p>Order ID: {orderId}</p>}
      {paymentId && <p>Payment ID: {paymentId}</p>}
      {awbNo && <p>Track your Order using AWB Number: {awbNo}</p>}
      <div className="success-checkmark">
        <Checkmark size="large" color="#28a745" />
      </div>

      <button
        className="continue-shopping-btn btn btn-dark"
        onClick={handleContinueShopping}
      >
        Continue Shopping
      </button>
    </div>
  );
};

export default SuccessPage;
