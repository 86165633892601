import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./TrackOrder.css";

function TrackOrder() {
  const [awbNumber, setAwbNumber] = useState("");
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const awb = params.get("awb");
    if (awb) {
      setAwbNumber(awb);
      fetchTrackingData(awb);
    }
  }, [location.search]);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`?awb=${awbNumber}`);
    fetchTrackingData(awbNumber);
  };

  const fetchTrackingData = async (awbNumber) => {
    try {
      console.log("Fetching tracking data for AWB:", awbNumber);
      const response = await fetch(`https://backend-ati-81lf.onrender.com/api/bluedart/track`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ awbNumber }),
      });

      if (!response.ok) {
        throw new Error("Invalid waybill number");
      }

      const trackingData = await response.json();
      setTrackingInfo(trackingData);
      setError("");
    } catch (error) {
      console.error("Error fetching tracking data:", error);
      setTrackingInfo(null);
      setError("Invalid waybill number. Please check and try again.");
    }
  };

  const getProgress = () => {
    if (!trackingInfo || !trackingInfo.ShipmentData || !trackingInfo.ShipmentData.Shipment) {
      return 0;
    }
    const status = trackingInfo.ShipmentData.Shipment[0].Status;
    if (status.includes("ONLINE SHIPMENT BOOKED")) return 25;
    if (status.includes("PICKED UP")) return 50;
    if (status.includes("SHIPPED")) return 75;
    if (status.includes("DELIVERED")) return 100;
    return 0;
  };

  return (
    <div className="track-order-container">
      <h1>Track Your Shipment</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={awbNumber}
          onChange={(e) => setAwbNumber(e.target.value)}
          placeholder="Enter AWB Number"
          required
        />
        <button type="submit">Track</button>
      </form>
      {trackingInfo && trackingInfo.ShipmentData && trackingInfo.ShipmentData.Shipment && (
        <div className="track-order-info">
          <ProgressBar
            percent={getProgress()}
            filledBackground="linear-gradient(to right, #4caf50, #66bb6a)"
            className="progress-bar"
          >
            <Step transition="scale">
              {({ accomplished }) => (
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src="/Static/Symbols/procurement.png"
                    alt="Shipment Booked"
                  />
                  <div>Ordered</div>
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src="/Static/Symbols/pickup.png"
                    alt="Picked Up"
                  />
                  <div>Picked Up</div>
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src="/Static/Symbols/shipped.png"
                    alt="Shipped"
                  />
                  <div>Shipped</div>
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width="30"
                    src="/Static/Symbols/delivered.png"
                    alt="Delivered"
                  />
                  <div>Delivered</div>
                </div>
              )}
            </Step>
          </ProgressBar>
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default TrackOrder;
